import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/reset-password/',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Index'),
        meta: {
            requiredAuth: true,
            roles: ['admin', 'pm', 'dev', 'client'],
        },
        children: [
            {
                path: '/profile',
                name: 'Profile',
                component: () => import(/* webpackChunkName: "home" */ '@/components/Users/Profile'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev', 'client'],
                },
            },
            {
                path: '',
                name: 'UserDashboard',
                component: () => import(/* webpackChunkName: "home" */ '@/views/UserDashboard'),
                meta: {
                    requiredAuth: true,
                    roles: ['pm', 'dev'],
                },
            },
            {
                path: '/projects',
                name: 'Projects',
                component: () => import(/* webpackChunkName: "home" */ '../views/Projects.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin'],
                },
            },
            {
                path: '/form-project',
                name: 'ProjectForm',
                component: () => import(/* webpackChunkName: "home" */ '@/components/Projects/ProjectForm'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/project-performance',
                name: 'ProjectPerformance',
                component: () => import(/* webpackChunkName: "home" */ '@/components/Projects/ProjectPerformance'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'dev', 'pm', 'client'],
                },
            },
            {
                path: '/project-monitoring',
                name: 'ProjectMonitoring',
                component: () => import(/* webpackChunkName: "home" */ '@/components/Projects/ProjectMonitoring'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'dev', 'pm', 'client'],
                },
            },

            {
                path: '/weekly-emails',
                name: 'WeeklyEmails',
                component: () => import(/* webpackChunkName: "home" */ '@/views/WeeklyEmails.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev'],
                },
            },
            {
                path: '/monitoring',
                name: 'WeeklyEmailsMonitoring',
                component: () => import(/* webpackChunkName: "home" */ '@/views/WeeklyEmailsMonitoring.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev'],
                },
            },
            {
                path: '/users',
                name: 'Users',
                component: () => import(/* webpackChunkName: "home" */ '../views/Users.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/form-user',
                name: 'UserForm',
                component: () => import(/* webpackChunkName: "home" */ '@/components/Users/UserForm'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/timetracker',
                name: 'Timetracker',
                component: () => import(/* webpackChunkName: "home" */ '../views/Timetracker.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/policies',
                name: 'Policies',
                component: () => import(/* webpackChunkName: "home" */ '@/views/Policies.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev'],
                },
            },
            {
                path: '/client',
                name: 'Client',
                component: () => import(/* webpackChunkName: "home" */ '../views/Client.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['client'],
                },
            },
            {
                path: '/project-feedback',
                name: 'Client Project Feedback',
                component: () => import(/* webpackChunkName: "home" */ '@/components/Dashboard/Client/ProjectsReview'),
                meta: {
                    requiredAuth: true,
                    roles: ['client'],
                },
            },
            {
                path: '/team-feedback',
                name: 'Client Team Feedback',
                component: () => import(/* webpackChunkName: "home" */ '@/components/Dashboard/Client/TeamReview'),
                meta: {
                    requiredAuth: true,
                    roles: ['client'],
                },
            },
            {
                path: '/rewards',
                name: 'Rewards',
                component: () => import(/* webpackChunkName: "rewards" */ '@/views/Rewards.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev'],
                },
            },
            {
                path: '/logs',
                name: 'Logs',
                component: () => import(/* webpackChunkName: "logs" */ '@/views/Logs'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/logs/email',
                name: 'EmailDetails',
                component: () => import(/* webpackChunkName: "logs" */ '@/components/Logs/Emails/Index'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin'],
                },
            },
            {
                path: '/plan-email',
                name: 'WeeklyPlanEmail',
                component: () => import(/* webpackChunkName: "home" */ '@/views/WeeklyPlanEmail.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev'],
                },
            },
            {
                path: '/eow-email',
                name: 'WeeklyEowEmail',
                component: () => import(/* webpackChunkName: "home" */ '@/views/WeeklyEowEmail.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev'],
                },
            },
            {
                path: '/email-tasks-monitoring',
                name: 'WeeklyEmailTasksMonitoring',
                component: () => import(/* webpackChunkName: "home" */ '@/views/WeeklyEmailTasksMonitoring.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'dev'],
                },
            },
            {
                path: '/leaderboard',
                name: 'Leaderboard',
                component: () => import(/* webpackChunkName: "home" */ '@/views/Leaderboard.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'client'],
                },
            },
            {
                path: '/task-radar',
                name: 'TaskRadar',
                component: () => import(/* webpackChunkName: "home" */ '@/components/WeeklyEmails/TaskRadar.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm', 'client'],
                },
            },
            {
                path: '/nfts',
                name: 'NFT',
                component: () => import(/* webpackChunkName: "nft" */ '@/views/Nfts.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/nfts/create',
                name: 'NFTCreate',
                component: () => import(/* webpackChunkName: "nft" */ '@/components/Nft/CreateAsset.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/nfts/:id/mint',
                name: 'NFTUpdate',
                component: () => import(/* webpackChunkName: "nft" */ '@/components/Nft/UpdateAsset.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin', 'pm'],
                },
            },
            {
                path: '/attendance',
                name: 'Attendance',
                component: () => import(/* webpackChunkName: "nft" */ '@/views/EmployeeAttendance.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['dev', 'pm'],
                },
            },
            {
                path: '/requests',
                name: 'Requests',
                component: () => import(/* webpackChunkName: "nft" */ '@/views/EmployeeRequests.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['dev', 'pm'],
                },
            },
            {
                path: '/admin-attendance',
                name: 'AdminAttendance',
                component: () => import(/* webpackChunkName: "home" */ '../views/AdminAttendanceIndex.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin'],
                },
            },
            {
                path: '/admin-requests',
                name: 'AdminRequests',
                component: () => import(/* webpackChunkName: "home" */ '../views/AdminRequests.vue'),
                meta: {
                    requiredAuth: true,
                    roles: ['admin'],
                },
            },
        ],
    },
    {
        path: '/404-page-not-found',
        component: () => import(/* webpackChunkName: "home" */ '@/views/PageNotFound'),
        name: '404-page-not-found',
    },
];
/*global process*/
/*eslint no-undef: "error"*/

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
